define('ember-sqwok/config/environment', [], function() {
  if (typeof FastBoot !== 'undefined') {
return FastBoot.config('ember-sqwok');
} else {

          var exports = {
            'default': {"API_HOST":"https://api.sqwok.im","API_PREFIX":"v1","APP_VERSION":"v1513","FASTBOOT_HOST_WHITELIST":"sqwokweb-ci-productionassetsbucket-qv9kar6oup41.s3.amazonaws.com","HCAPTCHA_SITE_KEY":"81a1abf1-edf4-4bfc-a040-85415e5b974b","IOT_HOST":"wss://stream.sqwok.im/mqtt","MEDIA_HOST":"https://media.sqwok.im","MIRAGE":false,"STATIC_HOST":"https://static.sqwok.im","WEB_HOST":"https://sqwok.im","environment":"production","fastboot":{"hostWhitelist":["sqwokweb-ci-productionassetsbucket-qv9kar6oup41.s3.amazonaws.com"]},"fontawesome":{"defaultPrefix":"far"},"locationType":"history","modulePrefix":"ember-sqwok","rootURL":"/","token":"eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJleHAiOjE1ODc2MzA3OTYsIm9yaWdfaWF0IjoxNTg3NjI5ODk2LCJhdHRhY2tTaGlwc09uTWFycyI6dHJ1ZSwidGFubkhhdXNlckdhdGUiOnRydWV9.dpf5d0OKdFbas0F7IAis1ACoyikj1-LIMIfNxBFBXKo","tokenPrefix":"uskoa","ember-cli-mirage":{"enabled":false,"usingProxy":false,"useDefaultPassthroughs":true},"ember-simple-auth-token":{"authorizationPrefix":"uskoa","headers":{"authorization":"uskoa eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJleHAiOjE1ODc2MzA3OTYsIm9yaWdfaWF0IjoxNTg3NjI5ODk2LCJhdHRhY2tTaGlwc09uTWFycyI6dHJ1ZSwidGFubkhhdXNlckdhdGUiOnRydWV9.dpf5d0OKdFbas0F7IAis1ACoyikj1-LIMIfNxBFBXKo"},"refreshLeeway":30,"refreshTokenPropertyName":"token","serverTokenEndpoint":"https://api.sqwok.im/v1/auth/obtain","serverTokenRefreshEndpoint":"https://api.sqwok.im/v1/auth/refresh","tokenExpirationInvalidateSession":true},"EmberENV":{"_APPLICATION_TEMPLATE_WRAPPER":false,"_JQUERY_INTEGRATION":false,"_TEMPLATE_ONLY_GLIMMER_COMPONENTS":true},"ember-component-css":{"terseClassNames":true}}
          };
          Object.defineProperty(exports, '__esModule', {value: true});
          return exports;
        
}
});
